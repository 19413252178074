<svg width="77px" height="76px" viewBox="0 0 77 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g id="Info-Services" transform="matrix(1, 0, 0, 1, 0.833313, 0)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <polygon id="Path-Copy-2" points="0.166666667 0 76.1666667 0 76.1666667 76 0.166666667 76"/>
    <g id="Desktop" transform="translate(0.000000, 8.000000)" fill="#1194DB">
      <path d="M58.1818182,0 L5.81818182,0 C2.61818182,0 0,2.61818182 0,5.81818182 L0,40.7272727 C0,43.9272727 2.61818182,46.5454545 5.81818182,46.5454545 L26.1818182,46.5454545 L26.1818182,52.2666667 L20.3636364,52.2666667 L20.3636364,57.6 L43.6363636,57.6 L43.6363636,52.2666667 L37.8181818,52.2666667 L37.8181818,46.5454545 L58.1818182,46.5454545 C61.3818182,46.5454545 64,43.9272727 64,40.7272727 L64,5.81818182 C64,2.61818182 61.3818182,0 58.1818182,0 Z M58.3871658,40.9326203 L6.02352941,40.9326203 L6.02352941,6.02352941 L58.3871658,6.02352941 L58.3871658,40.9326203 Z" id="Shape" fill-rule="nonzero"/>
      <path d="M19.741193,12 L19.741193,15.5218175 L15.7035228,15.5218175 L15.7035228,20.8647718 L19.2253403,20.8647718 L19.2253403,24.3859087 L15.7035228,24.3859087 L15.7035228,33.25 L12,33.25 L12,12 L19.741193,12 Z M28.1363891,12 L30.6863891,20.0753403 L33.2363891,12 L36.9705364,12 L36.9705364,33.25 L33.4180945,33.25 L33.4180945,20.3482386 L30.7163331,28.4535228 L30.6557646,28.4535228 L27.9540032,20.3482386 L27.9540032,33.25 L24.4022418,33.25 L24.4022418,12 L28.1363891,12 Z M45.4535228,12 L45.4535228,20.8035228 L48.6718175,20.8035228 L48.6718175,12 L52.3753403,12 L52.3753403,33.25 L48.6718175,33.25 L48.6718175,24.3253403 L45.4535228,24.3253403 L45.4535228,33.25 L41.75,33.25 L41.75,12 L45.4535228,12 Z" id="Combined-Shape"/>
    </g>
    <g id="Notification" transform="translate(35.226667, 36.300000)" fill="#5FD5FF" fill-rule="nonzero">
      <path d="M19.94,39.7 C22.14,39.7 23.94,37.9 23.94,35.7 L15.94,35.7 C15.94,37.9 17.72,39.7 19.94,39.7 Z M31.94,27.7 L31.94,17.7 C31.94,11.56 28.66,6.42 22.94,5.06 L22.94,3.7 C22.94,2.04 21.6,0.7 19.94,0.7 C18.28,0.7 16.94,2.04 16.94,3.7 L16.94,5.06 C11.2,6.42 7.94,11.54 7.94,17.7 L7.94,27.7 L3.94,31.7 L3.94,33.7 L35.94,33.7 L35.94,31.7 L31.94,27.7 Z" id="Shape"/>
      <path d="M11.1,2.86 L8.24,0 C3.44,3.66 0.28,9.3 0,15.7 L4,15.7 C4.3,10.4 7.02,5.76 11.1,2.86 L11.1,2.86 Z" id="Path"/>
      <path d="M35.88,15.7 L39.88,15.7 C39.58,9.3 36.42,3.66 31.64,0 L28.8,2.86 C32.84,5.76 35.58,10.4 35.88,15.7 Z" id="Path"/>
    </g>
  </g>
</svg>
